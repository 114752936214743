<template>
   <v-container>
      <crud
         api-path="user"
         title="User"
         :field="field"
         key-id="id"
         ref="crud"
      >
         <template v-slot:add_btn>
            <v-btn icon small color="indigo" title="buat user baru" @click="profile_id='new'"><v-icon>mdi-plus</v-icon></v-btn>
         </template>
         <template v-slot:row_act="row">
            <v-btn icon small color="indigo" title="edit user" @click="profile_id=row.id"><v-icon>mdi-pencil</v-icon></v-btn>
         </template>
      </crud>
      <user-form :uid="profile_id" v-on:close="closeFm"/>
   </v-container>
</template>

<script>
import crud from '@/components/crud2';
import UserForm from '../components/UserForm.vue';

export default {
   components: { crud, UserForm, },
   data: () => ({
      field: [
         { value: 'id', label: '#', ro: true, tbl: true },
         { value: 'fullname', label: 'Nama Lengkap', tbl: true, searchKey: true },
         { value: 'username', label: 'Username', tbl: true, rules: [v => !!v || 'Email atau username harus diisi']},
         { value: 'group_id', label: 'Group', master: ['user/group','id','name']},
         { value: 'group_name', label: 'Group', tbl: true, ro: true},
         { value: 'disabled', label: 'Disable', tbl: true, opts: [{value: 0, text:'No'}, {value: 1, text:'Yes'}]},
         { value: 'act', label: 'Aksi', ro: true, tbl: true, dis_sort: true }
      ],
      profile_id: null,
   }),
   methods: {
      closeFm(reload) {
         this.profile_id = null;
         if(typeof reload === 'boolean') this.$refs.crud.getData();
      },
   }
};
</script>
